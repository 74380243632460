



















































































































































import useMisc from "@/use/misc";
import useParticipant from "@/use/participant";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import countriesList from "@/assets/data/countries.json";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
      required: false,
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      specialization: [],
      items: [],
    });

    const fetchSpecialization = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("specialization")
        .then(({ data: { specializations } }) => {
          state.items = specializations;
          state.specialization = specializations.filter((item: any) =>
            props.data.data.specialization?.includes(item.id)
          );
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSpecialization);
    watch(() => props.data.data.specialization, fetchSpecialization);

    const {
      getParticipantStatusName,
      getParticipantInvoiceType,
    } = useParticipant({ root });
    const { getLanguageVersion } = useMisc({ root });

    return {
      state,
      countriesList,
      getParticipantStatusName,
      getParticipantInvoiceType,
      getLanguageVersion,
    };
  },
});
